(function() {
  function vendorModule(_export) {
    'use strict';
    return {
      execute() {
        _export(require('react'));
      },
    };
  }
  System.register([], vendorModule);
})();